var render = function () {
  var _vm$dataKondisiGiziku, _vm$dataKondisiGiziku2, _vm$activeProgram, _vm$profile, _vm$profile2, _vm$profile3, _vm$profile4, _vm$dataKondisiGiziku3, _vm$dataKondisiGiziku4, _vm$dataKondisiGiziku5, _vm$dataKondisiGiziku6, _vm$dataKondisiGiziku7, _vm$dataKondisiGiziku8, _vm$dataKondisiGiziku9, _vm$dataKondisiGiziku10, _vm$dataKondisiGiziku11, _vm$dataKondisiGiziku12, _vm$dataKondisiGiziku13, _vm$dataKondisiGiziku14, _vm$dataKondisiGiziku15, _vm$dataKondisiGiziku16, _vm$dataKondisiGiziku17, _vm$dataKondisiGiziku18, _vm$dataKondisiGiziku19, _vm$dataKondisiGiziku20, _vm$dataKondisiGiziku21, _vm$dataKondisiGiziku22, _vm$dataKondisiGiziku23, _vm$dataKondisiGiziku24, _Number, _vm$nutritionalIntake, _Number2, _vm$dataKondisiGiziku25, _vm$dataKondisiGiziku26, _vm$nutritionalIntake2, _vm$nutritionalIntake3, _Number3, _vm$nutritionalIntake4, _Number4, _vm$nutritionalIntake5, _vm$nutritionalIntake6, _vm$nutritionalIntake7, _Number5, _vm$nutritionalIntake8, _Number6, _vm$nutritionalIntake9, _vm$nutritionalIntake10, _vm$nutritionalIntake11, _Number7, _vm$nutritionalIntake12, _Number8, _vm$nutritionalIntake13, _vm$nutritionalIntake14, _vm$nutritionalIntake15, _Number9, _vm$nutritionalIntake16, _Number10, _vm$nutritionalIntake17, _vm$nutritionalIntake18, _vm$nutritionalIntake19, _vm$targetAndSuggesti, _vm$targetAndSuggesti2, _vm$targetAndSuggesti3, _vm$targetAndSuggesti4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "height": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "margin-inline": "auto",
      "background-color": ['#F2F2F2', '#FFF'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['1rem 1rem 80px 1rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', 'auto']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumbLabel
    }
  })], 1), _vm.isLoadingData ? _c('c-box', [_c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "padding": "128px 24px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl",
      "margin-bottom": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Memuat data Profil Gizi... ")])], 1)], 1) : !_vm.isHaveMealPlanMonths ? _c('EmptyState', {
    attrs: {
      "title": "Kamu belum memiliki program aktif",
      "description": "Yuk beli program agar mendapatkan panduan makan yang sesuai untukmu.",
      "button-text": "Beli Program",
      "button-route": "client.select-program"
    }
  }) : !((_vm$dataKondisiGiziku = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku !== void 0 && _vm$dataKondisiGiziku.id) ? _c('EmptyState', {
    attrs: {
      "title": "Profil gizimu masih kosong",
      "description": "Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!",
      "button-text": "Beranda",
      "button-route": "client.index"
    }
  }) : _c('c-flex', {
    attrs: {
      "background-color": "white",
      "padding": ['16px', '0'],
      "width": "100%",
      "flex-direction": "column",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "2rem",
      "margin-bottom": ['0.5rem', '1rem']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "padding": "0px",
      "width": "30px",
      "height": "30px",
      "min-width": "30x",
      "display": ['none', 'flex'],
      "align-items": "center"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-chevron-left.svg'),
      "height": "20px",
      "width": "20px"
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [!!((_vm$dataKondisiGiziku2 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku2 !== void 0 && _vm$dataKondisiGiziku2.id) ? _c('DownloadMealPlan', {
    attrs: {
      "program-id-local": (_vm$activeProgram = _vm.activeProgram) === null || _vm$activeProgram === void 0 ? void 0 : _vm$activeProgram.id,
      "month": _vm.$route.params.month
    }
  }) : _vm._e(), _c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "color": "neutral.black",
      "font-size": "20px",
      "font-weight": "700",
      "text-align": "center"
    }
  }, [_vm._v(" Profil Gizi ")])], 1)], 1), _c('c-box', [_c('c-flex', {
    attrs: {
      "margin-top": ['12px', '16px'],
      "w": "100%",
      "gap": ['10px', '20px'],
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "w": ['60px', '80px'],
      "h": ['60px', '80px'],
      "object-fit": "cover",
      "border-radius": ['60px', '80px'],
      "src": _vm.getPhotoUser((_vm$profile = _vm.profile) === null || _vm$profile === void 0 ? void 0 : _vm$profile.photoUrl)
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "color": "neutral.black",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" " + _vm._s((_vm$profile2 = _vm.profile) === null || _vm$profile2 === void 0 ? void 0 : _vm$profile2.firstName) + " " + _vm._s((_vm$profile3 = _vm.profile) === null || _vm$profile3 === void 0 ? void 0 : _vm$profile3.lastName) + " ")]), _c('c-text', {
    attrs: {
      "font-size": ['12px', '14px'],
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s((_vm$profile4 = _vm.profile) === null || _vm$profile4 === void 0 ? void 0 : _vm$profile4.phone) + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '20px'],
      "height": ['12px', '20px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-account-circle.svg'),
      "fill": "#555555",
      "width": "100%",
      "height": "100%"
    }
  })], 1), _c('c-text', {
    attrs: {
      "color": "neutral.darkGray",
      "font-size": ['10px', '14px'],
      "line-height": "normal"
    }
  }, [_vm._v(" Dibuat oleh " + _vm._s(_vm.getFullnameAndTitle("".concat(((_vm$dataKondisiGiziku3 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku3 === void 0 ? void 0 : (_vm$dataKondisiGiziku4 = _vm$dataKondisiGiziku3.nutritionist) === null || _vm$dataKondisiGiziku4 === void 0 ? void 0 : _vm$dataKondisiGiziku4.firstName) || '', " ").concat(((_vm$dataKondisiGiziku5 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku5 === void 0 ? void 0 : (_vm$dataKondisiGiziku6 = _vm$dataKondisiGiziku5.nutritionist) === null || _vm$dataKondisiGiziku6 === void 0 ? void 0 : _vm$dataKondisiGiziku6.lastName) || '').trim(), ((_vm$dataKondisiGiziku7 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku7 === void 0 ? void 0 : (_vm$dataKondisiGiziku8 = _vm$dataKondisiGiziku7.nutritionist) === null || _vm$dataKondisiGiziku8 === void 0 ? void 0 : _vm$dataKondisiGiziku8.education) || [])) + " - Ahli gizi Dietela ")])], 1), _c('c-flex', {
    attrs: {
      "margin-top": ['24px', '32px'],
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Kondisi Giziku ")])], 1), _c('BaseDivider'), (_vm$dataKondisiGiziku9 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku9 !== void 0 && _vm$dataKondisiGiziku9.nutritionalConditions ? _c('c-box', [_c('CardInfoKondisiGiziku', {
    attrs: {
      "type": ((_vm$dataKondisiGiziku10 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku10 === void 0 ? void 0 : (_vm$dataKondisiGiziku11 = _vm$dataKondisiGiziku10.nutritionalConditions) === null || _vm$dataKondisiGiziku11 === void 0 ? void 0 : _vm$dataKondisiGiziku11.calculationType) || '',
      "imt-status": _vm.parseInfo.status,
      "imt-value": _vm.parseInfo.value,
      "value-indicator": _vm.parseIndicatorValue,
      "popover-text": "Indeks Massa Tubuh (IMT) adalah pengukuran untuk menentukan apakah berat badan Anda sehat berdasarkan tinggi dan berat badan."
    }
  }), _c('c-grid', {
    attrs: {
      "display": ['grid', 'flex'],
      "template-columns": ['repeat(2, 1fr)', 'repeat(auto-fit, minmax(200px, 1fr))'],
      "margin-top": "16px",
      "gap": "16px",
      "flex-wrap": "wrap"
    }
  }, [_c('CardInfoOutline', {
    attrs: {
      "title": "Umur",
      "value": "".concat((_vm$dataKondisiGiziku12 = (_vm$dataKondisiGiziku13 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku13 === void 0 ? void 0 : (_vm$dataKondisiGiziku14 = _vm$dataKondisiGiziku13.nutritionalConditions) === null || _vm$dataKondisiGiziku14 === void 0 ? void 0 : _vm$dataKondisiGiziku14.age) !== null && _vm$dataKondisiGiziku12 !== void 0 ? _vm$dataKondisiGiziku12 : '-', " tahun")
    }
  }), _c('CardInfoOutline', {
    attrs: {
      "title": "Jenis kelamin",
      "value": (_vm$dataKondisiGiziku15 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku15 !== void 0 && (_vm$dataKondisiGiziku16 = _vm$dataKondisiGiziku15.nutritionalConditions) !== null && _vm$dataKondisiGiziku16 !== void 0 && _vm$dataKondisiGiziku16.gender && ((_vm$dataKondisiGiziku17 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku17 === void 0 ? void 0 : (_vm$dataKondisiGiziku18 = _vm$dataKondisiGiziku17.nutritionalConditions) === null || _vm$dataKondisiGiziku18 === void 0 ? void 0 : _vm$dataKondisiGiziku18.gender) === 'female' ? 'Perempuan' : (_vm$dataKondisiGiziku19 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku19 !== void 0 && (_vm$dataKondisiGiziku20 = _vm$dataKondisiGiziku19.nutritionalConditions) !== null && _vm$dataKondisiGiziku20 !== void 0 && _vm$dataKondisiGiziku20.gender && ((_vm$dataKondisiGiziku21 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku21 === void 0 ? void 0 : (_vm$dataKondisiGiziku22 = _vm$dataKondisiGiziku21.nutritionalConditions) === null || _vm$dataKondisiGiziku22 === void 0 ? void 0 : _vm$dataKondisiGiziku22.gender) === 'male' ? 'Laki-laki' : '-'
    }
  }), _c('CardInfoOutline', {
    attrs: {
      "title": _vm.parseInfo.cardInfo3.label,
      "value": _vm.parseInfo.cardInfo3.value
    }
  }), _c('CardInfoOutline', {
    attrs: {
      "title": "BB ideal",
      "value": _vm.parseInfo.cardInfo4.value
    }
  }), _vm.parseInfo.cardInfo5.value ? _c('CardInfoOutline', {
    attrs: {
      "title": "TB/PB ideal",
      "value": _vm.parseInfo.cardInfo5.value
    }
  }) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "width": ['100%', 'fit-content'],
      "max-width": ['100%', 'fit-content'],
      "margin-top": "16px"
    }
  }, [_c('CardInfoOutlineHighlight', {
    attrs: {
      "title": "Total Kebutuhan Energi (kkal)",
      "value": "".concat(((_vm$dataKondisiGiziku23 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku23 === void 0 ? void 0 : (_vm$dataKondisiGiziku24 = _vm$dataKondisiGiziku23.nutritionalConditions) === null || _vm$dataKondisiGiziku24 === void 0 ? void 0 : _vm$dataKondisiGiziku24.totalEnergyNeeded) || '-', " kkal")
    }
  })], 1)], 1) : _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Kondisi Giziku belum terisi"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "margin-top": ['24px', '32px'],
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Asupan Gizi ")])], 1), _c('BaseDivider'), _vm.nutritionalIntake ? _c('c-box', [_c('c-grid', {
    attrs: {
      "margin-top": "16px",
      "width": "100%",
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "16px"
    }
  }, [_c('CardInfoAsupanGizi', {
    key: "energy",
    attrs: {
      "title": "Energi",
      "current": (_Number = Number((_vm$nutritionalIntake = _vm.nutritionalIntake) === null || _vm$nutritionalIntake === void 0 ? void 0 : _vm$nutritionalIntake.energyIntakeCurrent)) !== null && _Number !== void 0 ? _Number : 0,
      "requirement": (_Number2 = Number((_vm$dataKondisiGiziku25 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku25 === void 0 ? void 0 : (_vm$dataKondisiGiziku26 = _vm$dataKondisiGiziku25.nutritionalConditions) === null || _vm$dataKondisiGiziku26 === void 0 ? void 0 : _vm$dataKondisiGiziku26.totalEnergyNeeded)) !== null && _Number2 !== void 0 ? _Number2 : 0,
      "status": (_vm$nutritionalIntake2 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake2 === void 0 ? void 0 : _vm$nutritionalIntake2.energyStatus,
      "note": (_vm$nutritionalIntake3 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake3 === void 0 ? void 0 : _vm$nutritionalIntake3.energyIntakeNote,
      "unit": "kkal"
    }
  }), _c('CardInfoAsupanGizi', {
    key: "protein",
    attrs: {
      "title": "Protein",
      "current": (_Number3 = Number((_vm$nutritionalIntake4 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake4 === void 0 ? void 0 : _vm$nutritionalIntake4.proteinIntakeCurrent)) !== null && _Number3 !== void 0 ? _Number3 : 0,
      "requirement": (_Number4 = Number((_vm$nutritionalIntake5 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake5 === void 0 ? void 0 : _vm$nutritionalIntake5.proteinRequirement)) !== null && _Number4 !== void 0 ? _Number4 : 0,
      "status": (_vm$nutritionalIntake6 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake6 === void 0 ? void 0 : _vm$nutritionalIntake6.proteinStatus,
      "note": (_vm$nutritionalIntake7 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake7 === void 0 ? void 0 : _vm$nutritionalIntake7.proteinIntakeNote,
      "unit": "gram"
    }
  }), _c('CardInfoAsupanGizi', {
    key: "fat",
    attrs: {
      "title": "Lemak",
      "current": (_Number5 = Number((_vm$nutritionalIntake8 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake8 === void 0 ? void 0 : _vm$nutritionalIntake8.fatIntakeCurrent)) !== null && _Number5 !== void 0 ? _Number5 : 0,
      "requirement": (_Number6 = Number((_vm$nutritionalIntake9 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake9 === void 0 ? void 0 : _vm$nutritionalIntake9.fatRequirement)) !== null && _Number6 !== void 0 ? _Number6 : 0,
      "status": (_vm$nutritionalIntake10 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake10 === void 0 ? void 0 : _vm$nutritionalIntake10.fatStatus,
      "note": (_vm$nutritionalIntake11 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake11 === void 0 ? void 0 : _vm$nutritionalIntake11.fatIntakenote,
      "unit": "gram"
    }
  }), _c('CardInfoAsupanGizi', {
    key: "carbohydrate",
    attrs: {
      "title": "Karbohidrat",
      "current": (_Number7 = Number((_vm$nutritionalIntake12 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake12 === void 0 ? void 0 : _vm$nutritionalIntake12.carboIntakeCurrent)) !== null && _Number7 !== void 0 ? _Number7 : 0,
      "requirement": (_Number8 = Number((_vm$nutritionalIntake13 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake13 === void 0 ? void 0 : _vm$nutritionalIntake13.carbohydrateRequirement)) !== null && _Number8 !== void 0 ? _Number8 : 0,
      "status": (_vm$nutritionalIntake14 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake14 === void 0 ? void 0 : _vm$nutritionalIntake14.carbohydrateStatus,
      "note": (_vm$nutritionalIntake15 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake15 === void 0 ? void 0 : _vm$nutritionalIntake15.carboIntakeNote,
      "unit": "gram"
    }
  }), _c('CardInfoAsupanGizi', {
    key: "fiber",
    attrs: {
      "title": "Serat",
      "current": (_Number9 = Number((_vm$nutritionalIntake16 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake16 === void 0 ? void 0 : _vm$nutritionalIntake16.fiberIntakeCurrent)) !== null && _Number9 !== void 0 ? _Number9 : 0,
      "requirement": (_Number10 = Number((_vm$nutritionalIntake17 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake17 === void 0 ? void 0 : _vm$nutritionalIntake17.fiberNeedsForAgeCategory)) !== null && _Number10 !== void 0 ? _Number10 : 0,
      "status": (_vm$nutritionalIntake18 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake18 === void 0 ? void 0 : _vm$nutritionalIntake18.fiberStatus,
      "note": (_vm$nutritionalIntake19 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake19 === void 0 ? void 0 : _vm$nutritionalIntake19.fiberIntakeNote,
      "unit": "gram"
    }
  })], 1)], 1) : _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Asupan Gizi belum terisi"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "margin-top": ['24px', '32px'],
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Kondisi Tubuh ")])], 1), _c('BaseDivider'), _vm.bodyConditions.length > 0 ? _c('c-box', {
    attrs: {
      "font-size": ['14px', '16px']
    }
  }, [_c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(3, 1fr)', 'repeat(4, 1fr)'],
      "background-color": ['primary.400', 'primary.50'],
      "color": ['neutral.white', 'neutral.black'],
      "font-size": ['14px', '16px'],
      "font-weight": "500",
      "text-align": "center",
      "border-top-left-radius": "12px",
      "border-top-right-radius": "12px",
      "margin-top": "16px",
      "margin-bottom": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['none', 'block'],
      "text-align": "center",
      "padding-block": "8px"
    }
  }, [_vm._v(" Indikator ")]), _c('c-box', {
    attrs: {
      "text-align": "center",
      "padding-block": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['none', 'inline'],
      "as": "span"
    }
  }, [_vm._v(" Nilai ")]), _vm._v(" Sekarang ")], 1), _c('c-box', {
    attrs: {
      "text-align": "center",
      "padding-block": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['none', 'inline'],
      "as": "span"
    }
  }, [_vm._v(" Nilai ")]), _vm._v(" Standard ")], 1), _c('c-box', {
    attrs: {
      "text-align": "center",
      "padding-block": "8px"
    }
  }, [_vm._v(" Status ")])], 1), _vm._l(_vm.bodyConditions, function (item, index) {
    var _item$indicator, _item$currentConditio, _item$standardValue, _item$conclusion;

    return _c('c-grid', {
      key: index,
      attrs: {
        "w": "100%",
        "template-columns": ['repeat(3, 1fr)', 'repeat(4, 1fr)'],
        "gap": ['6px', '16px'],
        "border-bottom-width": "1px",
        "border-bottom-color": "neutral.superLightGray",
        "padding-block": "4px",
        "margin-bottom": "8px",
        "text-align": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "grid-column": ['span 3 / span 3', 'span 1 / span 1'],
        "padding-block": "8px",
        "font-weight": "500",
        "background-color": ['primary.50', 'white']
      }
    }, [_vm._v(" " + _vm._s((_item$indicator = item === null || item === void 0 ? void 0 : item.indicator) !== null && _item$indicator !== void 0 ? _item$indicator : '-') + " ")]), _c('c-box', {
      attrs: {
        "padding-block": "8px",
        "text-transform": "lowercase",
        "align-self": "center"
      }
    }, [_vm._v(" " + _vm._s((_item$currentConditio = item === null || item === void 0 ? void 0 : item.currentCondition) !== null && _item$currentConditio !== void 0 ? _item$currentConditio : '-') + " " + _vm._s(item === null || item === void 0 ? void 0 : item.currentConditionUnit) + " ")]), _c('c-box', {
      attrs: {
        "padding-block": "8px",
        "text-transform": "lowercase",
        "align-self": "center"
      }
    }, [_vm._v(" " + _vm._s((_item$standardValue = item === null || item === void 0 ? void 0 : item.standardValue) !== null && _item$standardValue !== void 0 ? _item$standardValue : '-') + " " + _vm._s(item === null || item === void 0 ? void 0 : item.standardValueUnit) + " ")]), _c('c-box', {
      attrs: {
        "padding-block": ['4px', '8px'],
        "font-weight": "500",
        "background-color": _vm.getBobyConditionStatusColor(item === null || item === void 0 ? void 0 : item.conclusion).background,
        "color": _vm.getBobyConditionStatusColor(item === null || item === void 0 ? void 0 : item.conclusion).color,
        "border-radius": ['8px', '0px'],
        "height": "fit-content",
        "align-self": "center"
      }
    }, [_vm._v(" " + _vm._s((_item$conclusion = item === null || item === void 0 ? void 0 : item.conclusion) !== null && _item$conclusion !== void 0 ? _item$conclusion : '-') + " ")])], 1);
  })], 2) : _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Kondisi Tubuh belum terisi"
    }
  })], 1), !_vm.targetAndSuggestions ? _c('c-box', [_c('c-flex', {
    attrs: {
      "margin-top": ['24px', '32px'],
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Target dan Saran ")])], 1), _c('BaseDivider'), _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Target dan Saran belum terisi"
    }
  })], 1)], 1) : _c('c-box', [_c('c-text', {
    attrs: {
      "margin-top": ['24px', '32px'],
      "font-size": ['16px', '20px'],
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Tujuan Mealplan ")]), _c('BaseDivider'), _c('c-text', {
    attrs: {
      "margin-top": "10px",
      "color": "neutral.superDarkGray",
      "font-size": ['14px', '16px']
    }
  }, [_vm._v(" " + _vm._s(_vm.targetAndSuggestions.mealPlanGoals) + " ")]), _c('c-grid', {
    attrs: {
      "gap": "16px",
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "margin-top": "16px"
    }
  }, [_c('CardInfoThingsThatNeed', {
    attrs: {
      "label": "Yang perlu dipertahankan",
      "items": ((_vm$targetAndSuggesti = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti === void 0 ? void 0 : _vm$targetAndSuggesti.thingsThatNeedToBeMaintained) || []
    }
  }), _c('CardInfoThingsThatNeed', {
    attrs: {
      "color": "warning",
      "label": "Yang harus diperbaiki",
      "items": ((_vm$targetAndSuggesti2 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti2 === void 0 ? void 0 : _vm$targetAndSuggesti2.thingsThatNeedToBeImproved) || []
    }
  })], 1), _c('c-flex', {
    attrs: {
      "margin-top": ['48px', '72px'],
      "align-items": "center",
      "justify-content": "flex-start",
      "position": "relative"
    }
  }, [_c('c-image', {
    attrs: {
      "position": "absolute",
      "src": require('@/assets/images/undraw-target.svg'),
      "height": ['80px', '120px'],
      "left": ['20px']
    }
  }), _c('c-text', {
    attrs: {
      "padding-left": ['120px', '170px'],
      "font-size": ['16px', '20px'],
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Target ")])], 1), _c('BaseDivider'), _c('c-box', {
    attrs: {
      "as": "ol",
      "margin-top": ['24px', '42px'],
      "padding-left": "24px"
    }
  }, _vm._l(((_vm$targetAndSuggesti3 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti3 === void 0 ? void 0 : _vm$targetAndSuggesti3.targetForThisPeriod) || [], function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "as": "li",
        "font-size": ['14px', '16px']
      }
    }, [_c('c-text', {
      attrs: {
        "color": "neutral.superDarkGray"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }), 1), _c('c-flex', {
    attrs: {
      "margin-top": ['36px', '66px'],
      "align-items": "center",
      "justify-content": "flex-start",
      "position": "relative"
    }
  }, [_c('c-image', {
    attrs: {
      "position": "absolute",
      "src": require('@/assets/images/undraw-to-do-list.svg'),
      "height": ['65px', '90px'],
      "left": ['10px']
    }
  }), _c('c-text', {
    attrs: {
      "padding-left": ['120px', '170px'],
      "font-size": ['16px', '20px'],
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" Saran - saran ")])], 1), _c('BaseDivider'), _c('c-box', {
    attrs: {
      "as": "ol",
      "margin-top": ['24px', '42px'],
      "padding-left": "24px"
    }
  }, _vm._l(((_vm$targetAndSuggesti4 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti4 === void 0 ? void 0 : _vm$targetAndSuggesti4.nutritionAndLifestyleAdvice) || [], function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "as": "li",
        "font-size": ['14px', '16px']
      }
    }, [_c('c-text', {
      attrs: {
        "color": "neutral.superDarkGray"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }), 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "margin-top": "16px",
      "background-color": "secondary.50",
      "padding-block": "20px",
      "padding-inline": "20px",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "max-width": "330px",
      "flex-direction": "column",
      "align-items": "center",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": "#111",
      "font-weight": "500"
    }
  }, [_vm._v(" Temukan berbagai variasi golongan makan beserta takaran porsinya ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/images/image-lightbulb-moment.png'),
      "width": ['80px', '120px']
    }
  })], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "30px",
      "height": ['40px', '47px'],
      "font-weight": "500",
      "font-size": ['14px', '16px']
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.glossaries'
        });
      }
    }
  }, [_vm._v(" Lihat Glossary Foto Makanan ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }